import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import LogsTable from '../../components/LogsTable';

const Token = () => (
  <>
    <LogsTable />
  </>
);

export default Token;
